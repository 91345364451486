<template>
    <div class="avatar">
        <div class="avatar-image">
            <img v-show="image && !error && !loading" :src="image" class="w-1/3 uploading-image mt-6" @error="error = true"  @load="error = false" />
            <span v-show="(error && !loading) || !image">{{ title }}</span>
            <div v-if="loading" class="avatar-spinner">
                <fa class="fa-spin" :icon="['fad','spinner-third']" />
            </div>
        </div>

        <label v-if="allowEdit" class="flex flex__justify-center flex__align-center btn btn-sm cursor-pointer mt-3" for="file" :class="[routeIncludesAdmin ? 'btn-primary' : 'btn', { disabled: loading }]">
            <span>Edit avatar</span>
        </label>

        <input v-if="allowEdit" class="hidden" id="file" type="file" name="file" accept="image/*" @change="handleFile($event.target.files[0])" ref="fileInput"/>

        <button v-if="!error && allowEdit" class="btn-sm text-red-500 mt-1" @click.prevent="$emit('delete')">Delete avatar</button>
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null
        },
        allowEdit: {
            type: Boolean,
            default: true,
        },
    },
    
    data() {
        return {
            file: null,
            name: '',
            error: true,
        }
    },

    computed: {
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
    },
    
    methods: {
        handleFile(file) {
            if (!this.name) {
                this.name = file.name;
            }
            
            const image = file;
            this.file = file;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = async () => {
                await this.getPreview()
            };
        },

        async getPreview() {
            try {
                this.$emit('update', this.file);
            } catch(error) {
                const message = error?.response?.data?.errors.file?.join(',') || error?.response?.data?.message;

                this.$snotify.error(message);
            } finally {
                this.file = null;
                this.name = null;
                this.$refs.fileInput.value = '';
            }
        }
    },
}
</script>

<style scoped lang="scss">
input[type="file"] {
    display: none;
}

.avatar-image {
    width: 129px;
    height: 129px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: #E6E6E6;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        transform: translate(-50%, -50%);
        margin-top: 0;
    }

    .avatar-spinner, span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    span {
        font-size: 24px;
        color: white;
    }
}

.avatar {
    width: 129px;

    label, button {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }
}
</style>