<template>
    <div>
        <page-header>
            <template slot="tools">
                <div class="flex">
                    <router-link :to="{ name: 'admin.users'}" class="inline-block mr-4 btn-transparent">Cancel</router-link>
                    <button
                        :disabled="!allowSave"
                        @click="saveUser"
                        :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                        Save
                    </button>
                </div>
            </template>
        </page-header>


        <card class="border-radius mt-6" title-no-border bodyClass="p-0 -mx-6 overflow-visible" title="General information">
            <div class="flex flex-wrap w-full px-6 pb-8">
                <div v-if="this.edit" class="mr-8">
                    <image-input
                        class="mt-8"
                        :image="image"
                        :loading="loadingImage"
                        :title="selectedUser.name ? selectedUser.name[0] : ''"
                        :allow-edit="!isInputsDisabled"
                        @update="fetchUpdateAvatar"
                        @delete="fetchDeleteAvatar"
                    />
                </div>

                <div class="flex flex__column flex-grow">
                    <modal-field label="E-mail" class="w-full w__max-400 pr-8 mt-4">
                        <input
                            type="text"
                            v-model="selectedUser.email"
                            :class="{'border-red-500' : validationErrors.email}"
                            :disabled="isInputsDisabled"
                        />
                        <transition name="fade">
                            <span
                                v-if="validationErrors.email"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.email[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>

                    <modal-field label="Name" class="w-full w__max-400 pr-8 mt-4">
                        <input
                            type="text"
                            v-model="selectedUser.name"
                            :class="{'border-red-500' : validationErrors.name}"
                            :disabled="isInputsDisabled"
                        />
                        <transition name="fade">
                            <span
                                v-if="validationErrors.name"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.name[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field v-if="this.$route.name != 'settings.user.edit'" label="Organization" class="relative w-full w__max-400 pr-8 mt-4">
                        <multiselect v-model="selectedOrganization"
                            :options="organizations"
                            :multiple="false"
                            :allow-empty="false"
                            placeholder="Please select"
                            @input="fetchSitesByOrganization"
                            :class="{'border-red-500' : validationErrors.organization_id}"
                            track-by="name"
                            label="name"
                            :disabled="isInputsDisabled"
                        >
                            <span slot="noResult">
                            Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                        <transition name="fade">
                            <span
                                v-if="validationErrors.organization_id"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.organization_id[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field label="Roles" class="relative w-full w__max-400 pr-8 mt-4">
                        <multiselect v-model="selectedUser.roles"
                            :options="roles"
                            multiple
                            disabled-select-all
                            placeholder="Please select"
                            :class="{'border-red-500' : validationErrors.role_id}"
                            track-by="id"
                            label="name"
                            :disabled="isInputsDisabled"
                        >
                            <span slot="noResult">
                            Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                        <transition name="fade">
                            <span
                                v-if="validationErrors.role_id"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.role_id[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field label="Sites" class="relative w-full w__max-400 pr-8 mt-4">
                        <multiselect
                            v-model="selectedUser.sites"
                            :options="sites"
                            multiple
                            :disabled="isSitesDisabled || isInputsDisabled"
                            track-by="id"
                            label="name"
                        />

                        <transition name="fade">
                            <span
                                v-if="validationErrors.site_ids"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.site_ids[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field label="Area of responsibility" class="relative w-full w__max-400 pr-8 mt-4">
                        <input type="text" v-model="selectedUser.description">
                    </modal-field>
                    <modal-field label="Basic currency" class="mt-4 pr-8 w__max-400">
                        <multiselect
                            v-model="selectedUser.basic_currency"
                            :options="basicCurrencyOptions"
                        />
                    </modal-field>
                </div>

                <div v-if="this.$route.params.id && !isInputsDisabled" class="w-full mt-8 reset-row">
                    <button @click="resetPassword"
                        :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                    Reset password</button>
                </div>
                <modal
                    :show="showPasswordModal"
                    @hide="closePasswordModal"
                >
                    <h2 slot="header">Reset password</h2>
                    <div class="p-4 border-t border-gray-500" slot="body">
                        <p>You are about to reset the password for <strong>{{this.selectedUser.name}}</strong></p>
                        <p class="mt-4">A new password will be sent to <strong>{{this.selectedUser.email}}</strong></p>
                    </div>
                    <div class="flex w-full" slot="footer">
                        <button @click="sendResetLink" class="ml-auto btn-red">Reset</button>
                    </div>
                </modal>
                <modal
                    :show="loading"
                    :loader="true"
                >
                </modal>
            </div>
        </card>
    </div>
</template>

<script>
    import ImageInput from '~/components/ImageInput';

    import axios from 'axios'

    export default {
        components: {
            ImageInput,
        },

        name: 'AddUser',

        metaInfo () {
            return { title: 'Add user' }
        },

        data: () => ({
            selectedUser: {
                email: null,
                name: null,
                organization_id: null,
                roles: [],
                // site_id: null,
            },
            loading: false,
            showPasswordModal: false,
            userBeforeChange: null,
            validationErrors: [],
            organizations: [],
            selectedOrganization: null,
            sites: [],
            roles: [],
            image: null,
            loadingImage: false,
            basicCurrencyOptions: [
                'SEK', 'EUR', 'GBP', 'USD', 'DKK'
            ],
        }),

        created() {
            this.init();
        },

        watch: {
            selectedOrganization(val, oldVal) {
                if (val?.id === oldVal?.id || !oldVal) {
                    return;
                }

                this.selectedUser.sites = [];
                this.selectedUser.site_ids = [];
            },
            isAdminRoleChosen(val, oldVal) {
                if (val === oldVal) {
                    return;
                }

                if (val) {
                    this.selectedUser.sites = this.sites;

                    return;
                }

                this.selectedUser.sites = [];
            },
        },

        computed: {
            idAdmin() {
                return this.$store.getters['auth/isAdmin'];
            },
            isInputsDisabled() {
                return Boolean(
                    this.$store.getters['auth/roles']?.find(role => !['GOD USERS', 'Organization Admin'].includes(role.name))
                );
            },
            isAdminRoleChosen() {
                return Boolean(
                    this.selectedUser?.roles?.find(role => ['GOD USERS', 'Organization Admin'].includes(role.name))
                )
            },
            isSitesDisabled() {
                return !this.sites?.length || this.isAdminRoleChosen;
            },
            isGodUser() {
                return this.$store.getters['auth/isGodUser'];
            },
            user() {
                return this.$store.getters["auth/user"];
            },
            edit() {
                return this.$route.name != "admin.users.create";
            },
            routeIncludesAdmin() {
                return this.$route.path.includes('admin') ?  true : false;
            },
            allowSave() {
                if (this.isInputsDisabled) {
                    return false;
                }

                if(this.userBeforeChange !== JSON.stringify(this.selectedUser)) {
                    return true;
                } else {
                    return false;
                }
            }
        },


        methods: {
            init() {
                if(this.edit) {
                    this.fetchUser(this.$route.params.id);
                }

                this.fetchOrganization();
                this.fetchRoles();
            },
            async fetchDeleteAvatar() {
                if (this.isInputsDisabled) {
                    return;
                }

                this.loadingImage = true;

                try {
                    await axios.delete(this.$apiUrl.settings.avatar(this.selectedUser.id));
                    this.image = '';

                    if (this.selectedUser.id === this.user.id) {
                        this.$store.commit('auth/CHANGE_AVATAR');
                    }
                } catch(error) {
                    this.$snotify.error(error?.response?.data?.message);
                } finally {
                    this.loadingImage = false;
                }
            },

            async fetchUpdateAvatar(value) {
                if (this.isInputsDisabled) {
                    return;
                }

                this.loadingImage = true;

                try {
                    const formData = new FormData();
                    formData.append('avatar', value);

                    await this.fetchDeleteAvatar();

                    await axios.post(this.$apiUrl.settings.avatar(this.selectedUser.id), formData);

                    this.image = `${process.env.VUE_APP_API}/api/users/${this.selectedUser.id}/avatar?${Date.now()}`;

                    if (this.selectedUser.id === this.user.id) {
                        this.$store.commit('auth/CHANGE_AVATAR')
                    }
                } catch(error) {
                    this.$snotify.error(error?.response?.data?.message);
                } finally {
                    this.loadingImage = false;
                }
            },

            saveBeforeChangeState() {
                this.userBeforeChange = JSON.stringify(this.selectedUser);
            },
            
            async fetchOrganization() {
                try {
                        const { data } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000&include=sites');
                        this.organizations = data.data;
                    } catch (e) {
                        console.log(e, 'error');
                }
            },
            async fetchUser(id) {
                try {
                        const { data } = await axios.get(`${this.$apiUrl.users.list}/` + id);
                        this.setUser(data)

                        this.image = `${process.env.VUE_APP_API}/api/users/${data.data.id}/avatar?${Date.now()}`;
                        if(this.selectedUser) {
                            this.selectedOrganization = data.data.organization;

                            this.saveBeforeChangeState();
                        }
                    } catch (e) {
                        console.log(e, 'error');
                }
            },
            async fetchRoles() {
                try {
                    const { data } = await axios.get(`${this.$apiUrl.roles.base}?pageSize=1000`);
    
                    let roles = data.data;
                    if (!this.isGodUser) {
                        roles = roles.filter(role => role.name !== 'GOD USERS');
                    }

                    this.roles = roles;
                } catch (error) {
                    this.$snotify.error('Failed to fetch roles list');
                }
            },
            async setUser(data) {
                this.selectedUser = data.data;
                
                if (this.selectedUser.organization) {
                    this.selectedUser.organization_id = this.selectedUser.organization.id;
                }

                if (this.selectedUser.organization_id) {
                    const { data }  = await axios.get(`${this.$apiUrl.organizations.sites}/` + this.selectedUser.organization_id);
                    this.sites = data.data;
                }
            },
            async saveUser() {
                if (this.isInputsDisabled) {
                    return;
                }

                this.loading = true;
                let url = this.$apiUrl.users.list;
                let method = 'post';

                if(this.$route.params.id && this.edit) {
                    let id = this.$route.params.id;
                    url = `${this.$apiUrl.users.list}/` + id;
                    method = 'put'
                }

                try {   
                        const user = {
                            ...this.selectedUser,
                        }

                        user.site_ids = this.selectedUser.sites?.map(item => item.id);
                        user.roles_ids = this.selectedUser.roles?.map(role => role.id);

                        delete user.sites;

                        const { data } = await axios[method](url, user);
                        this.validationErrors = [];
                        this.$snotify.success(`User saved`);
                        
                        this.setUser(data);

                        this.loading = false;

                        if(!this.edit) {
                            let id = data.data.id;
                            this.$router.push({ name: "admin.users.edit", params: { id: id }});

                            this.fetchUser(id);
                        }

                        this.validationErrors = [];
                        this.loading = false;

                        this.saveBeforeChangeState();
                }
                catch (error) {

                    this.loading = false;
                    if(error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                }
            },
            async fetchSitesByOrganization(value) {
                this.selectedUser.organization = value;
                this.selectedUser.organization_id = value.id;
                const { data }  = await axios.get(`${this.$apiUrl.organizations.sites}/` + this.selectedOrganization.id);
                this.sites = data.data;
            },
            async sendResetLink() {
                if (!this.selectedUser) {
                    return;
                }
                this.closePasswordModal();
                this.loading = true;

                try {
                    const { data: { data } } = await axios.post(
                        `${this.$apiUrl.users.list}/` + this.selectedUser.id + "/reset-password"
                    );

                    this.$snotify.success(
                        "Password reset link sent to " + this.selectedUser.email
                    );
                    this.loading = false;
                }
                catch (error) {
                    this.loading = false;
                }
            },
            closePasswordModal() {
                this.showPasswordModal = false;
            },
            resetPassword() {
                this.showPasswordModal = true;
            },
        }
    }
</script>

<style lang="scss" >
    .border-red-500 {
        border-color: #f56565 !important;

        .multiselect__tags {
            border-color: #f56565 !important;
        }
    }
</style>
